import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const MetaTags = ({ metaData }) => {
  const { title, description, keywords } = metaData;

  return (
    <Helmet>
      {title && <title>{title}</title>}
      {title && <meta property="og:title" content={title} />}
      {title && <meta property="title" content={title} />}
      {description && <meta name="description" content={description} />}
      {description && <meta property="og:description" content={description} />}

      {keywords && <meta name="keywords" content={keywords} />}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={window.location.origin + window.location.pathname} />
      <link rel="canonical" href={window.location.origin + window.location.pathname} />
    </Helmet>
  );
};

MetaTags.propTypes = {
  metaData: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    keywords: PropTypes.string,
  }),
};

MetaTags.defaultProps = {
  metaData: {
    title: null,
    description: null,
    keywords: null,
  },
};

export default MetaTags;
