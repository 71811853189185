import {
  RTL_SET_IMAGES,
  RTL_UPDATE_IMAGES,
  RTL_UPDATE_REQUEST_DATA,
  RTL_UPDATE_PRIORITY_ITEM,
  RTL_ADD_PRIORITY_ITEM,
  RTL_UPDATE_II_SEARCH_PARAMS,
  RTL_RESET_REQUEST_DATA,
  RTL_RESET_REQUEST_FORM,
  RTL_RESET_STATE,
  RTL_UPDATE_FILTER_VALUES,
  RTL_RESET_FILTER_VALUES,
  RTL_UPDATE_ITEMS_FOR_TABS,
  RTL_DELETE_PRIORITY_ITEM,
} from 'modules/requestTheLook/store/constants';
import { II_SELECT_OWN_IMAGE } from 'modules/inspirationImage/store/constants';
import { updateIILibraryAction } from 'modules/inspirationImage/store/actions';

export const setImagesAction = (transformedList, list) => (dispatch) => {
  const imageIds = Object.values(list).map(({ id }) => `${id}`);
  dispatch(updateIILibraryAction(transformedList));
  dispatch({
    type: RTL_SET_IMAGES,
    payload: imageIds,
  });
};

export const updateImagesAction = (transformedList, list) => (dispatch) => {
  const imageIds = Object.values(list).map(({ id }) => `${id}`);
  dispatch(updateIILibraryAction(transformedList));
  dispatch({
    type: RTL_UPDATE_IMAGES,
    payload: imageIds,
  });
};

export const updateRequestDataAction = (payload) => (dispatch) => {
  dispatch({ type: RTL_UPDATE_REQUEST_DATA, payload });
};

export const selectImageAction = (imgId, requestType) => (dispatch, getState) => {
  const {
    inspirationImage: { library },
  } = getState();

  dispatch(
    updateRequestDataAction({
      inspirationImageId: imgId,
      requestType: library[imgId].type,
      rtlType: requestType,
    }),
  );
};

export const unselectImageAction = () => (dispatch) => {
  dispatch({ type: RTL_RESET_REQUEST_DATA });
  dispatch({
    type: II_SELECT_OWN_IMAGE,
    payload: true,
  });
};

export const resetRequestFormAction = () => (dispatch) => {
  dispatch({ type: RTL_RESET_REQUEST_FORM });
};

export const updatePriorityItemAction = (item) => (dispatch) => {
  dispatch({
    type: RTL_UPDATE_PRIORITY_ITEM,
    payload: item,
  });
};

export const addPriorityItemAction = () => (dispatch) => {
  dispatch({ type: RTL_ADD_PRIORITY_ITEM });
};

export const resetRequestStateAction = () => (dispatch) => {
  dispatch({ type: RTL_RESET_STATE });
};

export const updateRequestIISearchParamsAction = (params) => (dispatch) => {
  dispatch({ type: RTL_UPDATE_II_SEARCH_PARAMS, payload: params });
};

export const updateFilterValuesAction = (filterValues) => (dispatch) => {
  dispatch({ type: RTL_UPDATE_FILTER_VALUES, payload: filterValues });
  dispatch(updateRequestIISearchParamsAction({ offset: 0 }));
};

export const resetFilterValuesAction = () => (dispatch) => {
  dispatch({ type: RTL_RESET_FILTER_VALUES });
};

export const updateItemsForTabsAction = (payload) => (dispatch) => {
  dispatch({ type: RTL_UPDATE_ITEMS_FOR_TABS, payload });
};
export const deletePriorityItemAction = (item) => (dispatch) => {
  dispatch({ type: RTL_DELETE_PRIORITY_ITEM, payload: item });
};
